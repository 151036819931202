
import Vue from 'vue';

export default Vue.extend({
  name: 'ForeignEstablishmentTable',
  props: {
    rows: {
      type: Array as () => Array<Record<string, unknown>>,
      required: true,
    },
  },
  data() {
    return {
      header: {
        alias: [
          this.$t('myCompanies.modals.createGroup.taxIdentification'),
          this.$t('myCompanies.modals.createGroup.companyName'),
          this.$t('state'),
          this.$t('city'),
        ],
        columnsToShow: ['nationalRegister', 'name', 'state', 'city'] as string[],
      },
    };
  },
});
