var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("custom-table", {
    staticClass: "table",
    attrs: { "columns-data": _vm.header, data: _vm.rows, sortable: true },
    scopedSlots: _vm._u(
      [
        {
          key: "columns",
          fn: function ({ item, column }) {
            return [
              column === "nationalRegister"
                ? _c("td", [_vm._v(" " + _vm._s(item.nationalRegister) + " ")])
                : _vm._e(),
              column === "name"
                ? _c("td", [_vm._v(" " + _vm._s(item.name) + " ")])
                : _vm._e(),
              column === "state"
                ? _c("td", [_vm._v(" " + _vm._s(item.state) + " ")])
                : _vm._e(),
              column === "city"
                ? _c("td", [_vm._v(" " + _vm._s(item.city) + " ")])
                : _vm._e(),
            ]
          },
        },
        {
          key: "actions",
          fn: function (item) {
            return [_vm._t("actions", null, { item: item })]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }